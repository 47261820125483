import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import MAP_ICON from "../images/content/map_genetown_2014226a.jpg"

const AboutUs = () => {

  return (
    <Layout>
      <SEO
        title={`Used Lab Equipment Dealer & Scientific Instrument Broker`}
        description={`Surplus Solutions is committed to providing the highest quality used equipment, outstanding service, and industry expertise to meet the needs of the pharmaceutical, nutraceutical, and biotech industries. Contact us to learn the specialized solutions we can provide you in the asset management of your equipment.`}
        />
      <div className="row column">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Lyu-rp4i9NM"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen=""
        ></iframe>
        <h1>About Surplus Solutions</h1>
        <div className="row">
          <div className="medium-8 columns">
            <p>
              Surplus Solutions is committed to providing the highest quality
              used equipment, outstanding service, and industry expertise to
              meet the needs of the pharmaceutical, nutraceutical, and biotech
              industries. By focusing on these specific industries we have built
              close relationships with many of the top companies in the industry
              and have established an extensive network of direct sales
              contacts. Headquartered in Woonsocket, RI the company is located
              in close proximity to many major facilities in the industry.
            </p>
            <br />
            <p>
              Our company believes in fully understanding our client’s needs and
              expectations from the very beginning. It is our goal to have the
              same definition of success as our clients and to formulate the
              best strategies on how we are going to achieve our mutual goals.
              It all starts by assigning individuals to manage the sale of your
              equipment that have the necessary experience and knowledge to
              execute on the highest level. We pride ourselves on detailed
              communication and educating our clients on our processes and
              strategies. We work with our clients to design and implement
              programs that are systematic and streamlined in order to meet
              their expectations and guidelines.
            </p>
            <br />
            <p>
              Surplus Solutions offers several different services to our clients
              to help get their equipment sold. These services include
              brokerage, consignment, auction services and outright cash
              purchases. We tailor these services to meet the individual needs
              of our clients and design a specialized solution that will help
              them realize the maximum return for their surplus equipment.
            </p>
            <br />
            <p>
              At Surplus Solutions we pride ourselves on our intensive targeted
              marketing campaigns. All of our solutions depend heavily on our
              marketing initiatives to get the equipment sold for the best price
              in a reasonable amount of time. Without effective marketing,
              equipment can stay on the market for several years and never sell
              or sell for substantially less than the equipment is worth. We
              individually list available equipment on our website, with
              detailed specifications and actual pictures of every piece of
              equipment. The company then begins target marketing of the
              equipment though our extensive customer databases. We also engage
              in email solicitation, online marketing and phone based selling
              techniques to realize the greatest return on the equipment as
              possible.
            </p>
            <br />
            <p>
              Surplus Solutions would like to be your one-stop equipment broker
              for everything from one-off equipment sales to entire plant
              liquidations. Please contact Surplus Solutions today to find out
              more details on the specialized solutions we can provide you in
              the asset management of your equipment.
            </p>
            <br />
            <br />{" "}
          </div>
          <div className="medium-4 columns">
            <a
              href={MAP_ICON}
            >
              <img alt="Map" src={MAP_ICON} />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
